/*html-resume
Copyright Min-Zhong "John" Lu
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.*/

/* 
  My modifications:
  I wrapped @media print around this file, because I only want this style at print time
  I changed page size to A4
  I changed in :root --page-height: 23.4in
  I changed in :root --page-width: 8.3in;
  removed font-family: FontAwesome; and content: "\f041"; in .details .location::before{
  Changes height: var(--page-height);
  Further changes spcified below
*/

@media print {


/*
Begin Addition
*/

#rootsection {
  display: initial;
  grid-template-columns: initial;
  grid-template-areas: initial;
  grid-gap: initial;
  grid-auto-rows: initial;
  margin: initial;
  flex-wrap: initial;
  grid-area: initial;
  margin: initial;
  grid-area: initial;
  align-items: initial;
}

#main {
  display: initial;
  grid-template-columns: initial;
  grid-template-areas: initial;
  grid-gap: initial;
  grid-auto-rows: initial;
  margin: initial;
  flex-wrap: initial;
  grid-area: initial;
  margin: initial;
  grid-area: initial;
  align-items: initial;
}

#work {
  display: initial;
  grid-template-columns: initial;
  grid-template-areas: initial;
  grid-gap: initial;
  grid-auto-rows: initial;
}

#education {
  display: initial;
  grid-template-columns: initial;
  grid-template-areas: initial;
  grid-gap: initial;
  grid-auto-rows: initial;
}

#certificates {
  display: initial;
  grid-template-columns: initial;
  grid-template-areas: initial;
  grid-gap: initial;
  grid-auto-rows: initial;
}


#projects {
  display: initial;
  grid-template-columns: initial;
  grid-template-areas: initial;
  grid-gap: initial;
  grid-auto-rows: initial;
}


.blocks {
  display: initial;
  flex-flow: initial;
}

.details {
  display: initial;
  flex-flow: initial;
}


.details div {
  padding: initial;
}

.date {
  padding-top: initial;
}

.calendar {
  display: none !important;
}

#photo {
  grid-area: initial;
  display: none;
}

#menue {
  grid-area: initial;
  display: none;
}

#heading {
  display: none;
}

#menue {
  display: none;
}

/* End Addition */

a {
  color: black;
  text-decoration: none;
}

@page{
  size: A4;
  margin: 0;
}

*{
  box-sizing: border-box;
}

:root{
  --page-width: 8.3in;
  /*--page-height: 23.4in;*/
  --page-height: 35.1in;
  --main-width: 6.4in;
  --sidebar-width: calc(var(--page-width) - var(--main-width));
  --decorator-horizontal-margin: 0.2in;

  --sidebar-horizontal-padding: 0.2in;

  /* XXX: using px for very good precision control */
  --decorator-outer-offset-top: 10px;
  --decorator-outer-offset-left: -5.5px;
  --decorator-border-width: 1px;
  --decorator-outer-dim: 9px;
  --decorator-border: 1px solid #ccc;

  --row-blocks-padding-top: 5pt;
  --date-block-width: 0.6in;

  --main-blocks-title-icon-offset-left: -19pt;
}

body{
  width: var(--page-width);
  height: var(--page-height);
  margin: 0;
  font-family: "Open Sans", sans-serif; 
  font-weight: 300;
  line-height: 1.3;
  color: #444;
  hyphens: auto;
}

h1, h2, h3{
  margin: 0;
  color: #000;
}

li{
  list-style-type: none;
}

#main{
  float: left;
  width: var(--main-width);
  padding: 0.25in 0.25in 0 0.25in;
  font-size: 7pt;
}

#sidebar{
  float: right;
  position: relative; /* for disclaimer */
  width: var(--sidebar-width);
  height: var(--page-height);
  padding: 0.6in var(--sidebar-horizontal-padding);
  background-color: #f2f2f2;
  font-size: 8.5pt;
}

/* main */

/** big title **/
#title, h1, h2{
  text-transform: uppercase;
}

#title{
  position: relative;
  left: 0.55in;
  margin-bottom: 0.3in;
  line-height: 1.2;
}

#title h1{
  font-weight: 300;
  font-size: 18pt;
  line-height: 1.5;
}

#title h1 strong{
  margin: auto 2pt auto 4pt;
  font-weight: 600;
}

.subtitle{
  font-size: 8pt;
}

/*** categorial blocks ***/

.main-block{
  margin-top: 0.1in;
}

#main h2{
  position: relative;
  top: var(--row-blocks-padding-top);
  /* XXX: 0.5px for aligning fx printing */
  left: calc((var(--date-block-width) + var(--decorator-horizontal-margin)));
  font-weight: 400;
  font-size: 11pt;
  color: #555;
}

#main h2 > i{
  /* use absolute position to prevent icon's width from misaligning title */
  /* assigning a fixed width here is no better due to needing to align decorator
     line too */
  position: absolute;
  left: var(--main-blocks-title-icon-offset-left);
  z-index: 1; /* over decorator line */
  color: #444;
}

#main h2::after{ /* extends the decorator line */
  height: calc(var(--row-blocks-padding-top) * 2);
  position: relative;
  top: calc(-1 * var(--row-blocks-padding-top));
  /* XXX: 0.5px for aligning fx printing */
  left: calc(-1 * var(--decorator-horizontal-margin));
  display: block;
  border-left: var(--decorator-border);
  z-index: 0;
  line-height: 0;
  font-size: 0;
  content: ' ';
}

/**** minor tweaks on the icon fonts ****/
#main h2 > .fa-graduation-cap{
  left: calc(var(--main-blocks-title-icon-offset-left) - 2pt);
  top: 2pt;
}

#main h2 > .fa-suitcase{
  top: 1pt;
}

#main h2 > .fa-folder-open{
  top: 1.5pt;
}

/**** individual row blocks (date - decorator - details) ****/

.blocks{
  display: flex;
  flex-flow: row nowrap;
}

.blocks > div{
  padding-top: var(--row-blocks-padding-top);
}

.date{
  flex: 0 0 var(--date-block-width);
  padding-top: calc(var(--row-blocks-padding-top) + 2.5pt) !important;
  padding-right: var(--decorator-horizontal-margin);
  font-size: 7pt;
  text-align: right;
  line-height: 1;
}

.date span{
  display: block;
}

.date span:nth-child(2)::before{
  position: relative;
  top: 1pt;
  right: 5.5pt;
  display: block;
  height: 10pt;
  content: '|';
}

.decorator{
  flex: 0 0 0;
  position: relative;
  width: 2pt;
  min-height: 100%;
  border-left: var(--decorator-border);
}

/*
 * XXX: Use two filled circles to achieve the circle-with-white-border effect.
 * The normal technique of only using one pseudo element and
 * border: 1px solid white; style makes firefox erroneously either:
 * 1) overflows the grayshade background (if no background-clip is set), or
 * 2) shows decorator line which should've been masked by the white border
 *
 */

.decorator::before{
  position: absolute;
  top: var(--decorator-outer-offset-top);
  left: var(--decorator-outer-offset-left);
  content: ' ';
  display: block;
  width: var(--decorator-outer-dim);
  height: var(--decorator-outer-dim);
  border-radius: calc(var(--decorator-outer-dim) / 2);
  background-color: #fff;
}

.decorator::after{
  position: absolute;
  top: calc(var(--decorator-outer-offset-top) + var(--decorator-border-width));
  left: calc(var(--decorator-outer-offset-left) + var(--decorator-border-width));
  content: ' ';
  display: block;
  width: calc(var(--decorator-outer-dim) - (var(--decorator-border-width) * 2));
  height: calc(var(--decorator-outer-dim) - (var(--decorator-border-width) * 2));
  border-radius: calc((var(--decorator-outer-dim) - (var(--decorator-border-width) * 2)) / 2);
  background-color: #555;
}

.blocks:last-child .decorator{ /* slightly shortens it */
  margin-bottom: 0.25in;
}

/***** fine-tunes on the details block where the real juice is *****/

.details{
  flex: 1 0 0;
  padding-left: var(--decorator-horizontal-margin);
  padding-top: calc(var(--row-blocks-padding-top) - 0.5pt) !important; /* not sure why but this is needed for better alignment */
}

.details header{
  color: #000;
}

.details h3{
  font-size: 9pt;
}

.main-block:not(.concise) .details div{
  margin: 0.18in 0 0.1in 0; 
}

.main-block:not(.concise) .blocks:last-child .details div{
  margin-bottom: 0;
}

.main-block.concise .details div:not(.concise){
  /* use padding to work around the fact that margin doesn't affect floated
     neighboring elements */
  padding: 0.05in 0 0.07in 0;
}

.details .place{
  float: left;
  font-size: 7.5pt;
}

.details .location{
  float: right;
}

.details div{
  clear: both;
}

.details .location::before{
  display: inline-block;
  position: relative;
  right: 3pt;
  top: 0.25pt;
  /*font-family: FontAwesome;*/
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  /*content: "\f041";*/
}

/***** fine-tunes on the lists... *****/

#main ul{
  padding-left: 0.07in;
  margin: 0.08in 0;
}

#main li{
  margin: 0 0 0.025in 0;
}

/****** customize list symbol style ******/
#main li::before{
  position: relative;
  margin-left: -4.25pt;
  content: '• ';
}

.details .concise ul{
  margin: 0 !important;
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
}

.details .concise li{
  margin: 0 !important;
}

.details .concise li{
  margin-left: 0 !important;
}



/* sidebar */

#sidebar h1{
  font-weight: 400;
  font-size: 11pt;
}

.side-block{
  margin-top: 0.5in; 
}

#contact ul{
  margin-top: 0.05in;
  padding-left: 0;
  font-family: "Source Code Pro";
  font-weight: 400;
  line-height: 1.75;
}

#contact li > i{
  width: 9pt; /* for text alignment */
  text-align: right;
}

#skills{
  line-height: 1.5;
}

#skills ul{
  margin: 0.05in 0 0.15in;
  padding: 0;
}

#disclaimer{
  position: absolute;
  bottom: var(--sidebar-horizontal-padding);
  right: var(--sidebar-horizontal-padding);
  font-size: 7.5pt;
  font-style: italic;
  line-height: 1.1;
  text-align: right;
  color: #777;
}

#disclaimer code{
  color: #666;
  font-family: "Source Code Pro";
  font-weight: 400;
  font-style: normal;
}

/* additional changes to original */

.details .summary{
  float: left;
}

#languages ul{
  margin: 0.05in 0 0.15in;
  padding: 0;
}

.subtitle {
  /*padding: 0px;*/
  /*margin: auto;*/
  width: 96% !important;
}

/* end additional changes */

}